import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import MainContext from '../context/main/mainContext';

const PrivateRoute = ({ component: Component, ...rest }) => {

    const mainContext = useContext(MainContext);
    const { user } = mainContext;

    return (
        <Route { ...rest } render={props => !localStorage.getItem("@qrToken") ? (
            <Redirect to="/" />
        ) : (
            <Component {...props} />
        )
        } 
      />
    );
}

export default PrivateRoute;
