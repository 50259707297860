// Global
export const LOADING = "LOADING";
export const END_LOADING = "END_LOADING";
export const TRIGGER_DASH_RESET = "TRIGGER_DASH_RESET";
// End Global

// Auth
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_SUCCESS_FROM_VERIFY = "REGISTER_SUCCESS_FROM_VERIFY";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SUCCESS_FROM_VERIFY = "LOGIN_SUCCESS_FROM_VERIFY";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const OWNERLOGIN_SUCCESS = "OWNERLOGIN_SUCCESS";
export const OWNERREGISTER_SUCCESS = "OWNERREGISTER_SUCCESS";
export const OWNER_LOADED = "OWNER_LOADED";
export const START_REGISTRATION = "START_REGISTRATION";
export const END_REGISTRATION = "END_REGISTRATION";
export const USER_EXISTS = "USER_EXISTS";
// End Auth

// Main
export const CHANGE_INDEX = "CHANGE_INDEX";
export const TOGGLE_POP_UP = "TOGGLE_POP_UP";
export const TOGGLE_WIDGET = "TOGGLE_WIDGET";
export const SET_SNACKBAR = "SET_SNACKBAR";


export const LOADING_PAGE_INFO = "LOADING_PAGE_INFO";
export const LOADING_PAGE_BUILD = "LOADING_PAGE_BUILD";
export const PAGE_CHECKED = "PAGE_CHECKED";
export const PAGE_LOADED = "PAGE_LOADED";
export const PAGE_PUBLISHED = "PAGE_PUBLISHED";
export const PAGE_ERROR = "PAGE_ERROR";
export const EMPTY_PAGE = "EMPTY_PAGE";
export const ENTRY_HANDLING = "ENTRY_HANDLING";
export const LOADING_ENTRY_INFO = "LOADING_ENTRY_INFO";
export const ENTRIES_LOADED = "ENTRIES_LOADED";
export const ADD_SECTION = "ADD_SECTION";
export const ADD_SECTION_NEW_ARR = "ADD_SECTION_NEW_ARR";
export const REMOVE_SECTION = "REMOVE_SECTION";
export const CREATE_RESET = "CREATE_RESET";
export const LOGIN_RESET = "LOGIN_RESET";
export const VERIFY_RESET = "VERIFY_RESET";
export const FORGOT_RESET = "FORGOT_RESET";
export const RESET_RESET = "RESET_RESET";
export const SETUP_RESET = "SETUP_RESET";
export const CLEAR_ICONS = "CLEAR_ICONS";
// End Main

// NEW

export const SIGN_IN_SUCCESS_NA = "SIGN_IN_SUCCESS_NA";
export const SIGN_OUT = "SIGN_OUT";

export const CHANGE_QR_TYPE = "CHANGE_QR_TYPE";
export const CHANGE_QR_BACKGROUND = "CHANGE_QR_BACKGROUND";
export const CHANGE_QR_COLOR = "CHANGE_QR_COLOR";
export const CHANGE_QR_COLOR2 = "CHANGE_QR_COLOR2";
export const CHANGE_QR_BORDER = "CHANGE_QR_BORDER";
export const CHANGE_QR_STYLE = "CHANGE_QR_STYLE";
export const TOGGLE_QR_GENERATED = "TOGGLE_QR_GENERATED";
export const TOGGLE_QR_LOADING = "TOGGLE_QR_LOADING";
export const TOGGLE_QR_LOGO = "TOGGLE_QR_LOGO";
export const CHANGE_QR_LOGO = "CHANGE_QR_LOGO";
export const CHANGE_QR_LOGO_FILE = "CHANGE_QR_LOGO_FILE";
export const CHANGE_QR_LOGO_URL = "CHANGE_QR_LOGO_URL";
export const CHANGE_QR_DATA = "CHANGE_QR_DATA";
export const CHANGE_QR_DATA_EXTRA_FIELD = "CHANGE_QR_DATA_EXTRA_FIELD";
export const QR_LOADED = "QR_LOADED";
export const AI_QR_LOADED = "AI_QR_LOADED";
export const SINGLE_QR_LOADED = "SINGLE_QR_LOADED";
export const QR_LISTS_LOADED = "QR_LISTS_LOADED";
export const QR_LOAD_2_COLLECTION = "QR_LOAD_2_COLLECTION";
export const QR_ADDED_2_COLLECTION = "QR_ADDED_2_COLLECTION";
export const TRIGGER_ACC_POPUP = "TRIGGER_ACC_POPUP";
export const QR_ARCHIVED = "QR_ARCHIVED";
export const QR_URL_CHANGE = "QR_URL_CHANGE";
export const QR_NAME_CHANGE = "QR_NAME_CHANGE";
export const QR_NAMES_CHANGE = "QR_NAMES_CHANGE";
export const GET_LAST_7_DAYS = "GET_LAST_7_DAYS";
export const GET_LAST_30_DAYS = "GET_LAST_30_DAYS";
export const GET_LAST_365_DAYS = "GET_LAST_365_DAYS";
export const GET_ALL_POPS = "GET_ALL_POPS";
export const GET_COMPARER_POPS = "GET_COMPARER_POPS";
export const TRIGGER_SNACKBAR = "TRIGGER_SNACKBAR";
export const TOGGLE_CREATE_POP_UP = "TOGGLE_CREATE_POP_UP";
export const TOGGLE_SUB_POP_UP = "TOGGLE_SUB_POP_UP";
export const TOGGLE_VIEWSCAN_POP_UP = "TOGGLE_VIEWSCAN_POP_UP";
export const TOGGLE_CONTACT_POP_UP = "TOGGLE_CONTACT_POP_UP";
export const SET_STRIPE_CUSTOMER_NA = "SET_STRIPE_CUSTOMER_NA";
export const SET_PRO_SUCCESS = "SET_PRO_SUCCESS";
export const SET_POPL_CARD = "SET_POPL_CARD";
export const CLEAR_POPL_CARD = "CLEAR_POPL_CARD";
export const BLOGS_LOADED = "BLOGS_LOADED";
export const CHANGE_AI_QR_LOGO_FILE = "CHANGE_AI_QR_LOGO_FILE";
export const CHANGE_AI_QR_LOGO_URL = "CHANGE_AI_QR_LOGO_URL";
export const AI_QR_CLEARED = "AI_QR_CLEARED";
export const TOGGLE_FROM_AI_BTN = "TOGGLE_FROM_AI_BTN";


// End NEW
