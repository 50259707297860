/* eslint-disable no-console */
/* eslint-disable import/no-webpack-loader-syntax */
import firebase from "./firebase.config";

export const signInWithEmailPassword = async ({ email, password }) => {
  const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
  const { user } = userCredential;
  return user.getIdToken();
};

export const signUpWithEmailPassword = async ({ email, password }, cb) => {
  try {
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const { user } = userCredential;
    user.getIdToken().then((token) => {
      cb(null, { idToken: token, refreshToken: user.refreshToken });
    });
  } catch (error) {
    console.log(error);
    cb(error);
  }
};

export const setQrLogo = async (qrid, src) => {
  try {
    console.log(src);
    const path = firebase.storage().ref(`/qrcodegenerator/${qrid}`);
    const response = await path.putString(src, "data_url", {
      contentType: "image/jpg",
    });
    console.log(response);
    return response;

  } catch (err) {
    console.log(err);
    return "server fail";
  }
};

export const getQrCodeData = async (qrid) => {
  let logo = "";
  console.log(qrid);

  const getLogo = async () => {
    try {
      const path = firebase.storage().ref(`/qrcodegenerator/${qrid}`);
      logo = await path.getDownloadURL();
      // checking contenttype
      const meta = await path.getMetadata();
      if (!meta.contentType.includes("image")) logo = "";
    } catch (error) {
      if (error.code === "storage/object-not-found") {
        const path = firebase.storage().ref(`/qrcodegenerator/${qrid}`);
        logo = await path.getDownloadURL();
      }
    }
  };


  await Promise.all([getLogo()]);
  console.log(logo);
  return logo;
};

export const uploadImage = async (file, folderName) => {
  const outputData = {
    file,
    name: file.name,
  };

  const storageRef = firebase.storage().ref();
  const uploadTask = storageRef.child(`${folderName || ""}/${outputData.name}`).put(outputData.file);
  return new Promise((res) => {
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING:
            console.log("Upload is running");
            break;
          default: return null;
        }
      },
      (error) => {
        switch (error.code) {
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
          default: return null;
        }
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          res(downloadURL);
        });
      });
  });
};
