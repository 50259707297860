/* eslint-disable */

import axios from "axios";

export const slackBot = async (type, info1, info2, info3) => {

  if (type === "Message") {
    const hook =
      "https://hooks.slack.com/services/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";
    try {
      const slackBody = {
        attachments: [{
          color: "#444",
          blocks: [
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*From:* ${info1}`
              }
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `Subject: ${info3}`
              }
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `${info2}`
              }
            },
          ]
        }]
      };

      await axios.post(hook, JSON.stringify(slackBody), {
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.post["Content-Type"];
            return data;
          }
        ]
      });
      
    } catch (e) {
      console.log(e);
    }
  }

  return null;
};
