import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyD6KaMONe_eOS0dy2L3AZAHD-pOM4NNqII",
  authDomain: "poplco.firebaseapp.com",
  databaseURL: "https://poplco.firebaseio.com",
  projectId: "poplco",
  storageBucket: "poplco.appspot.com",
  messagingSenderId: "1016915496422",
  appId: "1:1016915496422:web:36a6c71b52a16fa2ceda27",
  measurementId: "G-38RXN89B96",
};
firebase.initializeApp(config);

export default firebase;
