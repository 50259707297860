let recaptchaRendered = false;
let recaptchaToken = "";
const recaptchaKey = "6LdmRMQnAAAAAKVSsPhWNfC-cyzcY6JQvA2lURzq";

const captchaCallback = (token) => {
  recaptchaToken = token;
};

const renderRecaptcha = () => {
  if (recaptchaRendered) {
    return;
  }
  window.grecaptcha.ready(() => {
    window.grecaptcha.render("recaptcha-container", {
      sitekey: recaptchaKey,
      "data-size": "invisible",
      callback: (recaptchaToken) => captchaCallback(recaptchaToken),
    });
  });
  recaptchaRendered = true;
};

export const getRecaptchaToken = async () => {
  if (!window.grecaptcha) {
    console.log("Recaptcha not loaded (blocked most likely)");
    return "";
  }
  renderRecaptcha();
  // Sleep for 100ms to allow recaptcha to render
  await new Promise((resolve) => setTimeout(resolve, 100));
  window.grecaptcha.execute();

  // Wait for the token to be populated
  while (!recaptchaToken) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }
  const token = recaptchaToken;

  // Reset recaptcha
  recaptchaToken = "";
  window.grecaptcha.reset();

  return token;
};
