import {
  CHANGE_QR_TYPE,
  CHANGE_QR_DATA,
  CHANGE_QR_DATA_EXTRA_FIELD,
  CHANGE_QR_COLOR,
  CHANGE_QR_LOGO,
  TOGGLE_QR_LOGO,
  CHANGE_QR_BORDER,
  TOGGLE_QR_LOADING,
  TOGGLE_QR_GENERATED,
  QR_LOADED,
  SIGN_IN_SUCCESS_NA,
  SIGN_OUT,
  QR_LOAD_2_COLLECTION,
  QR_ADDED_2_COLLECTION,
  QR_LISTS_LOADED,
  CHANGE_QR_STYLE,
  CHANGE_QR_BACKGROUND,
  TRIGGER_ACC_POPUP,
  QR_ARCHIVED,
  GET_LAST_7_DAYS,
  GET_ALL_POPS,
  GET_LAST_30_DAYS,
  TRIGGER_SNACKBAR,
  TOGGLE_CREATE_POP_UP,
  TOGGLE_SUB_POP_UP,
  SET_STRIPE_CUSTOMER_NA,
  SET_PRO_SUCCESS,
  CHANGE_QR_LOGO_FILE,
  CHANGE_QR_LOGO_URL,
  QR_URL_CHANGE,
  QR_NAME_CHANGE,
  TOGGLE_CONTACT_POP_UP,
  GET_LAST_365_DAYS,
  SINGLE_QR_LOADED,
  QR_NAMES_CHANGE,
  CHANGE_QR_COLOR2,
  TOGGLE_VIEWSCAN_POP_UP,
  GET_COMPARER_POPS,
  BLOGS_LOADED,
  SET_POPL_CARD,
  CLEAR_POPL_CARD,
  AI_QR_LOADED,
  CHANGE_AI_QR_LOGO_FILE,
  CHANGE_AI_QR_LOGO_URL,
  AI_QR_CLEARED,
  TOGGLE_FROM_AI_BTN
} from "./types";

export default (state, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS_NA: {
      return {
        ...state,
        user: action.payload,
        createAccPopUp: false,
      };
    }
    case SIGN_OUT: {
      return {
        ...state,
        user: null,
        QRgenerated: false,
        optionalDownload: "",
        QRid: ""
      };
    }
    case TOGGLE_CREATE_POP_UP: {
      return {
        ...state,
        createAccPopUp: false
      };
    }
    case TRIGGER_SNACKBAR: {
      return {
        ...state,
        snackbar: action.snackbar,
      };
    }
    case TRIGGER_ACC_POPUP: {
      return {
        ...state,
        createAccPopUp: action.payload,
        createAccPopUpMessage: action.msg,
        optionalDownload: action.optionalDownload,
      };
    }
    case TOGGLE_SUB_POP_UP: {
      return {
        ...state,
        subscribePopUp: action.payload,
      };
    }
    case TOGGLE_VIEWSCAN_POP_UP: {
      return {
        ...state,
        viewScanpopup: action.payload,
      };
    }
    case TOGGLE_CONTACT_POP_UP: {
      return {
        ...state,
        contactPopUp: action.payload,
      };
    }
    case SET_STRIPE_CUSTOMER_NA: {
      return {
        ...state,
        user: {
          ...state.user,
          stripeCustomer: action.payload
        },
        stripeCustomer: action.payload,
      };
    }
    case SET_PRO_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          isPro: 1
        },
      };
    }
    case QR_ARCHIVED: {
      return {
        ...state,
        savedQRs: state.savedQRs.filter(x => x._id !== action.payload),
      };
    }
    case QR_URL_CHANGE: {
      return {
        ...state,
        savedQRs: state.savedQRs.map((x) => {
          if (x._id == action.payload) {
            return { ...x, dataExtraField1: action.url }
          }
          return x;
        }),
      };
    }
    case QR_NAME_CHANGE: {
      return {
        ...state,
        savedQRs: state.savedQRs.map((x) => {
          if (x._id == action.payload) {
            return { ...x, name: action.name }
          }
          return x;
        }),
      };
    }
    case QR_NAMES_CHANGE: {
      return {
        ...state,
        savedQRs: state.savedQRs.map((x) => {
          if (x._id == action.payload) {
            return { ...x, name: action.name, description: action.desc }
          }
          return x;
        }),
      };
    }
    case GET_LAST_7_DAYS: {
      return {
        ...state,
        last7Data: action.payload,
      };
    }
    case GET_LAST_30_DAYS: {
      return {
        ...state,
        last30Data: action.payload,
      };
    }
    case GET_LAST_365_DAYS: {
      return {
        ...state,
        last365Data: action.payload,
      };
    }
    case GET_ALL_POPS: {
      return {
        ...state,
        allTimeData: action.payload,
      };
    }
    case GET_COMPARER_POPS: {
      return {
        ...state,
        comparerData: action.payload,
      };
    }
    case QR_LOAD_2_COLLECTION: {
      return {
        ...state,
        QRtoCollectionLoader: action.payload,
        QRlogoFile: null
      };
    }
    case QR_ADDED_2_COLLECTION: {
      return {
        ...state,
        QRinCollection: true,
        QRisSavable: false,
        QRtoCollectionLoader: false,
      };
    }
    case CHANGE_QR_TYPE:
      if (action.payload === state.QRtype) {
        return state;
      }
      if (action.payload === "Wifi") {
        return {
          ...state,
          QRtype: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRloading: false,
          QRid: "",
          QRdata: "",
          QRredirectURL: "",
          QRdataExtraField1: "WPA",
          QRdataExtraField2: "",
          QRdataExtraField3: "",
          QRdataExtraField4: "",
          QRdataExtraField5: "",
          QRdataExtraField6: "",
          QRdataExtraField7: "",
          QRdataExtraField8: "",
          QRdataExtraField9: "",
          QRdataExtraField10: ""
        };
      }
      if (action.payload === "Phone Number") {
        return {
          ...state,
          QRtype: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRloading: false,
          QRid: "",
          QRdata: "",
          QRredirectURL: "",
          QRdataExtraField1: "text",
          QRdataExtraField2: "",
          QRdataExtraField3: "",
          QRdataExtraField4: "",
          QRdataExtraField5: "",
          QRdataExtraField6: "",
          QRdataExtraField7: "",
          QRdataExtraField8: "",
          QRdataExtraField9: "",
          QRdataExtraField10: ""
        };
      }
      return {
        ...state,
        QRtype: action.payload,
        QRgenerated: false,
        optionalDownload: "",
        QRloading: false,
        QRid: "",
        QRdata: "",
        QRredirectURL: "",
        QRdataExtraField1: "",
        QRdataExtraField2: "",
        QRdataExtraField3: "",
        QRdataExtraField4: "",
        QRdataExtraField5: "",
        QRdataExtraField6: "",
        QRdataExtraField7: "",
        QRdataExtraField8: "",
        QRdataExtraField9: "",
        QRdataExtraField10: "",
        fromAiQR: false,
      };
    case CHANGE_QR_BACKGROUND:
      return {
        ...state,
        QRbackground: action.payload,
        QRisSavable: true
      };
    case CHANGE_QR_COLOR:
      return {
        ...state,
        QRcolor: action.payload,
        QRisSavable: true
      };
    case CHANGE_QR_COLOR2:
      return {
        ...state,
        QRcolor2: action.payload,
        QRisSavable: true
      };
    case CHANGE_QR_BORDER:
      return {
        ...state,
        QRborder: action.payload,
        QRisSavable: true
      };
    case CHANGE_QR_STYLE:
      return {
        ...state,
        QRstyle: action.payload,
        QRisSavable: true
      };
    case TOGGLE_QR_LOADING:
      return {
        ...state,
        QRloading: action.payload
      };
    case TOGGLE_QR_GENERATED:
      return {
        ...state,
        QRredirectURL: action.redirect,
        QRdata: action.payload,
        QRid: action.id,
        QRinCollection: action.isInCollection,
        QRgenerated: true,
        QRisSavable: false,
        QRloading: false
      };
    case TOGGLE_QR_LOGO:
      return {
        ...state,
        QRlogo: action.payload,
        QRisSavable: true
      };
    case CHANGE_QR_LOGO:
      return {
        ...state,
        QRlogoData: action.payload,
        QRisSavable: true
      };
    case CHANGE_QR_LOGO_FILE:
      return {
        ...state,
        QRlogoFile: action.payload
      };
    case CHANGE_AI_QR_LOGO_FILE:
      return {
        ...state,
        aiQRlogoFile: action.payload,
        aiQRlogoURL: ""
      };
    case CHANGE_AI_QR_LOGO_URL:
      return {
        ...state,
        aiQRlogoURL: action.payload
      };
    case CHANGE_QR_LOGO_URL:
      return {
        ...state,
        QRlogoUrl: action.payload
      };
    case CHANGE_QR_DATA:
      return {
        ...state,
        QRdata: action.payload,
        QRgenerated: false,
        optionalDownload: "",
        QRid: ""
      };
    case AI_QR_LOADED:
      return {
        ...state,
        aiQRs: [...state.aiQRs, ...action.payload]
      };
    case AI_QR_CLEARED:
      return {
        ...state,
        aiQRs: []
      };
    case QR_LOADED:
      return {
        ...state,
        QRdata: action.payload
      };
    case SINGLE_QR_LOADED:
      return {
        ...state,
        singleQR: action.payload
      };
    case QR_LISTS_LOADED:
      return {
        ...state,
        savedQRs: action.saved
      };
    case TOGGLE_FROM_AI_BTN:
      if (action.payload) {
        return {
          ...state,
          QRtype: "AI Generated",
          QRlogo: false,
          fromAiQR: true
        }
      }
      return {
        ...state,
        QRtype: "Website URL",
        fromAiQR: action.payload
      };
    case BLOGS_LOADED:
      return {
        ...state,
        blogs: action.blogs
      };
    case SET_POPL_CARD:
      return {
        ...state,
        poplCardData: action.payload
      };
    case CLEAR_POPL_CARD:
      return {
        ...state,
        poplCardData: null,
        QRgenerated: false,
        optionalDownload: "",
        QRid: ""
      };
    case CHANGE_QR_DATA_EXTRA_FIELD:
      if (action.number == "1") {
        return {
          ...state,
          QRdataExtraField1: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRid: ""
        };
      }
      if (action.number == "2") {
        return {
          ...state,
          QRdataExtraField2: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRid: ""
        };
      }
      if (action.number == "3") {
        return {
          ...state,
          QRdataExtraField3: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRid: ""
        };
      }
      if (action.number == "4") {
        return {
          ...state,
          QRdataExtraField4: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRid: ""
        };
      }
      if (action.number == "5") {
        return {
          ...state,
          QRdataExtraField5: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRid: ""
        };
      }
      if (action.number == "6") {
        return {
          ...state,
          QRdataExtraField6: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRid: ""
        };
      }
      if (action.number == "7") {
        return {
          ...state,
          QRdataExtraField7: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRid: ""
        };
      }
      if (action.number == "8") {
        return {
          ...state,
          QRdataExtraField8: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRid: ""
        };
      }
      if (action.number == "9") {
        return {
          ...state,
          QRdataExtraField9: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRid: ""
        };
      }
      if (action.number == "10") {
        return {
          ...state,
          QRdataExtraField10: action.payload,
          QRgenerated: false,
          optionalDownload: "",
          QRid: ""
        };
      }
      return state;
    default:
      return state;
  }
};
