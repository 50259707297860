import React, { useEffect, useContext } from "react";
import MainContext from "../context/main/mainContext";

const RedirectQR = props => {
  document.title = "Popl QR Code";

  const mainContext = useContext(MainContext);
  const { getQRDataByid, QRdata } = mainContext;

  const QRinUrl = props.location.pathname.substring(1);

  const regexItem = new RegExp("^[a-zA-Z0-9_]*$");

  useEffect(() => {
    if (props.location.pathname[props.location.pathname.length - 1] === "/") {
      if (
        regexItem.test(QRinUrl.slice(0, props.location.pathname.length - 2))
      ) {
        getQRDataByid(QRinUrl.slice(0, props.location.pathname.length - 2));
      }
    } else if (regexItem.test(QRinUrl)) {
      getQRDataByid(QRinUrl);
    }
  }, [props.location]);

  useEffect(() => {
    if (QRdata === "") {
      return;
    } else if (QRdata === "Invalid") {
      props.history.push("/");
    } else {
      window.location = QRdata;
    }
  }, [QRdata]);

  return (
    <div>
      <div></div>
    </div>
  );
};

export default RedirectQR;
